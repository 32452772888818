/* Basic Styling */
.menu-barr {
    display: flex;
    justify-content: center;
    background-color: #222222;
  }
  
  .menu-listt {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
  }
  
  .menu-itemm {
    margin: 0 15px;
  }
  
  .menu-itemm a {
    color: white;
    text-decoration: none;
    font-size: 16px;
  }
  
  .menu-itemm a:hover {
    color: #f0a500;
  }
  
  /* Menu icon for small screens */
  .menu-iconn {
    display: none;
    cursor: pointer;
    font-size: 24px;
    color: white;
  }
  
  /* Responsive for small screens */
  @media (max-width: 768px) {
    .menu-listt {
      flex-direction: column;
      position: absolute;
      top: 60px;
      right: 0;
      background-color: #080808;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
    }
  
    .menu-listt.open {
      max-height: 300px; /* Adjust based on the number of items */
    }
  
    .menu-itemm {
      margin: 10px 0;
      text-align: center;
    }
  
    .menu-iconn {
      display: block;
      position: absolute;
      top: 10px;
      right: 20px;
    }
  
    .menu-barr {
      position: relative;
    }
  }
  
  /* Hide menu items by default on small screens */
  @media (max-width: 768px) {
    .menu-listt {
      display: none;
    }
  
    .menu-listt.open {
      display: block;
    }
  }
  