/* Default styles for desktop and larger screens */
.Main-category {
  width: 35%;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.category-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 35%;
  padding: 10px 8px;
}

.category-card {
  display: flex;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.category-image {
  width: 10px;
  height: 10px;
  /* object-fit: cover; */
  margin-right: 10px;
}

.category-info {
  display: flex;
  flex-direction: column;
}

.category-time {
  color: rgb(40, 192, 10);
  margin-bottom: 10px;
}

/* Tablet responsiveness */
@media (max-width: 1024px) {
  .Main-category {
    width: 90%;
  }

  .category-list {
    width: 80%;
    padding: 10px 20px;
  }

  .category-card {
    padding: 15px;
    flex-direction: column;
    align-items: center;
  }

  .category-image {
    width: 120px;
    height: 120px;
    margin-bottom: 15px;
    margin-right: 0;
  }

  .category-info {
    align-items: center;
    text-align: center;
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .Main-category {
    width: 95%;
  }

  .category-list {
    width: 100%;
    padding: 10px 15px;
  }

  .category-card {
    padding: 10px;
    flex-direction: column;
    align-items: center;
  }

  .category-image {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }

  .category-info {
    align-items: center;
    text-align: center;
  }

  .category-time {
    font-size: 14px;
  }
}
