/* Default styles for desktop and larger screens */
.Main-category {
    width: 85%;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
  }
  
  .category-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 70%;
    padding: 10px 28px;
  }
  
  .category-card {
    display: flex;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .category-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-right: 20px;
    margin-top: 10px;
  }
  
  .category-info {
    display: flex;
    flex-direction: column;
  }
  
  .title {
    color: rgb(22, 157, 137);
  }
  .category-time {
    color: rgb(178, 59, 35);
    margin-bottom: 10px;
  }
  
  /* Tablet responsiveness */
  @media (max-width: 1024px) {
    .Main-category {
      width: 90%;
    }
  
    .category-list {
      width: 80%;
      padding: 10px 20px;
    }
  
    .category-card {
      padding: 15px;
      flex-direction: column;
      align-items: center;
    }
  
    .category-image {
      width: 120px;
      height: 120px;
      margin-bottom: 15px;
      margin-right: 0;
    }
  
    .category-info {
      align-items: center;
      text-align: center;
    }
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .Main-category {
      width: 95%;
    }
  
    .category-list {
      width: 100%;
      padding: 10px 15px;
    }
  
    .category-card {
      padding: 10px;
      flex-direction: column;
      align-items: center;
    }
  
    .category-image {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
    }
  
    .category-info {
      align-items: center;
      text-align: center;
    }
  
    .category-time {
      font-size: 14px;
    }
  }
  