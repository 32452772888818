.logo-container {
    text-align: left;
    margin-left: 10%;
    margin-top: 10px;
  }
  


  .logo-e {
    font-size: 3.5rem; /* Make it larger */
    color: #ff6347; /* Unique color (Tomato) */
    font-weight: bold; /* Make it bold */
    text-shadow: 2px 2px 4px #000000; /* Add some shadow */
    font-style: italic; /* Make it italic */
    letter-spacing: 2px; /* Space out the letter */
    transform: rotate(-45deg); /* Add a slight rotation */
    display: inline-block;
  }
  
  .logo-text {
    font-family: 'Arial', sans-serif;
    font-size: 2.0rem;
    color: #333;
    font-weight: bold; /* Make it bold */
    text-shadow: 2px 2px 4px #eb5c09; /* Add some shadow */
  }
  


  .logo-pc {
    font-size: 2.5rem; /* Make it larger */
    color: #0e08c5; /* Unique color (Tomato) */
    font-weight: bold; /* Make it bold */
    text-shadow: 2px 2px 4px #10eef6; /* Add some shadow */
    /* font-style: italic; Make it italic */
    letter-spacing: 2px; /* Space out the letter */
    /* transform: rotate(-5deg); Add a slight rotation */
    display: inline-block;
  }


  .asy{
    color: #ffffff;

  }
 
  .tagline {
    font-size: 1.2rem;
    color: #2c2e2f;
    position: relative;
    display: inline-block;
    
  }
  
  .tagline::before,
  .tagline::after {
    content: '------';
    color: #505253;
    font-size: 1rem;
    margin: 0 10px;
  }


  @keyframes colorChange {
    0% {
      color: #ff0000; /* Red */
    }
    25% {
      color: #00ff00; /* Green */
    }
    50% {
      color: #0000ff; /* Blue */
    }
    75% {
      color: #ffff00; /* Yellow */
    }
    100% {
      color: #ff0000; /* Red */
    }
  }
  
  .tagline {
   
    
    animation: colorChange 5s infinite;
  }
  
/* Media query for mobile screens */
@media (max-width: 768px) {
  
  

  .logo-e {
      font-size: 2.5rem;
      letter-spacing: 1px;
      transform: rotate(0deg);
  }

  .logo-text {
      font-size: 1.5rem;
  }

  .logo-pc {
      font-size: 2rem;
  }

  .tagline {
      font-size: 0rem;
   
  }

 
}