.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .about-us-title {
    font-size: 2.5em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-us-description {
    font-size: 1.2em;
    line-height: 1.6;
    color: #555;
  }
  
  .highlight {
    color: #007bff; /* Highlight color for the brand name */
    font-weight: bold;
  }
  