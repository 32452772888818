/* SocialMediaSidebar.css */
.sidebar {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  background-color: #333;
  border-radius: 0 10px 10px 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  border-radius: 50%;
}

.icon img {
  width: 24px;
  height: 24px;
}

.icon:hover {
  transform: scale(1.2);
  background-color: #ccc8c8;
}

.facebook {
  background-color: #3b5998;
}

.twitter {
  background-color: #ebecee;
}

.instagram {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
}

.linkedin {
  background-color: #0077b5;
}

.github {
  background-color: #8b8888;
}
