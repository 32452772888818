/* .custom-nav {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  } */
  /* .nav-divider {
    height: 5px; /* Default height */
    /* width: 1px;   /* Default thickness */
    /* background-color: rgb(83, 179, 238);
    margin: 0 5px; Space around the divider
  }
   */ 


   /* .moving-text {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    animation: moveText 30s linear infinite;
  }
  
  @keyframes moveText {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  .moving-text p {
    margin: 0;
    font-size: 20px;  /* Adjust the font size as needed */



    /* color: rgb(255, 255, 255);    
    font-weight: bold; 
  }
    */



    .moving-text {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      text-align: center;
      white-space: nowrap;
      animation: moveText 30s linear infinite;
    }
    
    @keyframes moveText {
      0% {
        transform: translateX(100%); /* Start from the right */
      }
      100% {
        transform: translateX(-100%); /* End on the left */
      }
    }
    
    .moving-text p {
      margin: 0;
      font-size: 18px;  /* Adjust the font size as needed */
      color: white;     /* Adjust the color as needed */
      font-weight: bold; /* Optional: Make text bold */
    }
    