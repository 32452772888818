/* .Mainn-category {
    width: 50%;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
  } */


  .container {
    display: flex;
    margin-top: 2%;
  }
  
  .text-area {
    flex: 0 0 70%; /* Takes up 70% of the width */
    padding: 20px; /* Optional padding */
    background-color: #f9f9f9;
    margin: auto;
 
}


.text-mainArea{
  
  border: 1px solid black; 

}
  
  .empty-area {
    flex: 0 0 30%; /* Takes up 30% of the width */
   
    background-color: #f9f9f9;
   
    
  }
  