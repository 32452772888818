.mission-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .mission-title {
    font-size: 2.5em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .mission-description {
    font-size: 1.2em;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
  }
  
  .highlight {
    color: #007bff; /* Highlight color for the brand name */
    font-weight: bold;
  }
  