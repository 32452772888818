.next-button {
    background-color: #55caee;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
   margin-left: 80px;
   margin-bottom: 30px;

  
  }
  .next-button2 {
    background-color: #55caee;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
   margin-left: 10px;

  
  }




  
  .next-button svg {
    margin-left: 10px;
    vertical-align: middle;
  }
  
  .next-button:hover {
    background-color: #3e8e41;
  }
  .next-button2:hover {
    background-color: #3e8e41;
  }