.container {
    max-width: 80%;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    
  }
  
  .title {
    color: '#0fbf5f';

    margin-top:'20px';
    padding-left: '30px' 
  }

  @media (max-width: 600px) {
    .title {
      font-size: 1.5rem;
    }
    .section-title {
      font-size: 1.25rem;
    }
    .intro, p {
      font-size: 0.9rem;
    }
  }
  