/* For fixed button at bottom-left */
.whatsapp-button {
  position: fixed;
  bottom: 20px; /* Adjust bottom position */
  left: 20px;   /* Adjust left position */
  z-index: 1000;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Icon size */
.whatsapp-icon {
  width: 50px;  /* Set default size */
  height: 50px; /* Set default size */
}

/* For mobile devices */
@media (max-width: 768px) {
  .whatsapp-button {
    bottom: 15px;
    left: 15px;
  }

  .whatsapp-icon {
    width: 45px; /* Smaller icon on mobile */
    height: 45px;
  }
}

/* For very small devices like extra small screens */
@media (max-width: 480px) {
  .whatsapp-button {
    bottom: 10px;
    left: 10px;
  }

  .whatsapp-icon {
    width: 40px; /* Even smaller icon on very small devices */
    height: 40px;
  }
}

  