/* Basic Styling */
.menu-bar {
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(3, 244, 27, 0.1);
  padding: 10px 28px;
  width: 85%;
  margin: 0 auto;
  z-index: 1000;
  position: relative;
  border-radius: 8px;
}

.menu-list {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu-item {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-item a, .menu-item span {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-top: 5px; /* Adds space between the icon and the text */
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.menu-item a:hover, .menu-item span:hover, .menu-icon:hover  {
  color: #f0a500;
}

.menu-item:hover .menu-icon, 
.menu-item:hover span {
  color: #f0a500;
}

.menu-icon {
  font-size: 24px;
  color: #333;
}

/* Menu icon for small screens */
.menu-icons {
  display: none;
  cursor: pointer;
  font-size: 28px;
  color: rgb(40, 39, 39);
  transition: transform 0.3s ease;
}

.menu-icons:hover {
  transform: scale(1.1);
}

/* Responsive for small screens */
@media (max-width: 768px) {
  .menu-bar {
    width: 80%;
    margin-top: -50px;
    margin-left: 10%;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }

  .menu-icons {
    display: block;
    margin-left: auto;
    margin-right: 20px;
  }

  .menu-list {
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: rgb(130, 203, 249);
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    border-radius: 8px;
  }

  .menu-list.open {
    max-height: 700px; /* Adjust based on the number of items */
  }

  .menu-item {
    margin: 10px 0;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }

  .menu-item a, .menu-item span {
    text-decoration: none;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }

  .menu-item a:hover, .menu-item span:hover {
    color: #f0a500;
  }

  .menu-icon {
    font-size: 26px;
    margin-bottom: 5px;
  }
}

/* Hide menu items by default on small screens */
@media (max-width: 768px) {
  .menu-list {
    display: none;
  }

  .menu-list.open {
    display: flex;
    flex-direction: column;
  }
}
